import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = [
  " - 스테이지파이브, 카카오페이 내 통신판매 서비스 ‘스마트폰샵’ 론칭, 프로모션 진행",
  " - 카카오 서비스 이용권 혜택, 카카오 웰컴 기프트 키트 등 다양한 편익 제공",
  " - 수납방법에 따른 할인 혜택도… 카카오페이로 요금수납 시 매월 통신 요금의 7% 추가 제휴 할인",
]

const content = () => (
  <div>
    <style>
      {
        "\
        .pressDetailPage .pressDetailBody .contentArea .desc {\
            color: #08205C;\
          }\
        .pressDetailPage .pressDetailBody .contentArea .descImage {\
          display: table;\
          width: 100%;\
        }\
        .pressDetailPage .pressDetailBody .contentArea .descImage div {\
          display: table-cell;\
          vertical-align: middle;\
          width: 24%;\
        }\
        "
      }
    </style>
    <p>
      <br />
      ㅣ 스테이지파이브(공동대표 서상원, 이제욱)은 9월 15일(화) 카카오페이 내 통신판매 서비스 ‘스마트폰샵’을 오픈하여 새로운 통신
      플랫폼 사업을 본격적으로 시작한다. 해당 플랫폼을 통하면 카카오톡 앱을 벗어나지 않고 스마트폰 구매부터 개통, 상담까지
      가능해진다. 해당 샵은 카카오페이 내 ‘스마트폰샵’에서 확인할 수 있다.
      <br />
    </p>
    <br />
    <div className="descImage">
      <div>
        <img src="/images/press/3/diuGmadA.png" alt="진입" />
      </div>
      <div style={{ width: 15 + "px" }}></div>
      <div>
        <img src="/images/press/3/RyAqXigr.jpg" alt="첫페이지" />
      </div>
      <div style={{ width: 15 + "px" }}></div>
      <div></div>
      <div style={{ width: 15 + "px" }}></div>
      <div></div>
    </div>
    <br />
    <div className="desc bold">그림1 스마트폰샵 진입화면</div>
    <br />
    <p>
      ㅣ 이를 통해 카카오페이 이용자는 누구나 ‘카카오페이 인증’으로 별도의 신분증이나 서류 없이 간편한 본인인증 후, 다양한
      스마트폰 및 통신 요금 상품을 손쉽게 비교하고 선택/가입할 수 있다. 특히, 카카오페이머니 또는 카카오페이에 등록된 카드로
      자동납부 신청도 가능하다. 복잡했던 이전 통신 가입 절차와 달리, 본인인증부터 결제까지 앱 안에서 이루어지기 때문에 소비자들은
      새로운 차원의 편리한 서비스 경험을 체험할 수 있게 된다.
      <br />
    </p>
    <br />
    <p>
      ㅣ 특히, 카카오페이를 통해 자동납부 신청 시 7%의 추가 제휴 할인을 받을 수 있다. 예를 들어 고객이 선택 약정 할인 25%를 선택
      후 카카오페이 자동납부를 등록하면 최대 32%까지 할인혜택을 받을 수 있게 되는 것이다. 이를 통해 큰 폭의 가계통신비 절감효과를
      얻는 것이 가능하다..
      <br />
    </p>
    <br />
    <p>
      ㅣ 언택트 구매가 어려운 소비자는 카카오톡의 ‘핀다이렉트샵' 채널을 통하면 쉽게 도움받을 수 있다. 해당 톡채널을 통해 대화형
      상품 탐색(챗봇) / 전문 플래너와 실시간, 유형별 상담 / 간단한 FAQ는 키워드 입력으로 즉각 확인이 가능하기 때문이다..
      <br />
    </p>
    <div className="descImage">
      <div>
        <img src="/images/press/3/PABBGaJg.png" alt="상담" />
      </div>
      <div></div>
      <div></div>
    </div>
    <div className="desc bold">그림2 톡채널을 통한 간편상담</div>
    <br />
    <br />
    <div className="descImage">
      <div>
        <img src="/images/press/3/RyAqXigr.jpg" alt="첫페이지" />
      </div>
      <div style={{ width: 10 + "px" }}></div>
      <div>
        <img src="/images/press/3/oqxdBmEu.jpg" alt="요금제 선택 페이지" />
      </div>
      <div style={{ width: 10 + "px" }}></div>
      <div>
        <img src="/images/press/3/mqacjAhc.jpg" alt="단말 선택 페이지" />
      </div>
      <div style={{ width: 10 + "px" }}></div>
      <div>
        <img src="/images/press/3/MbhFCrKJ.png" alt="번호 확인 페이지" />
      </div>
    </div>
    <br />
    <div className="descImage">
      <div>
        <div>
          <img src="/images/press/3/xFVFajPa.jpg" alt="민감 정보 인증 페이지" />
        </div>
        <div style={{ width: 10 + "px" }}></div>
        <div>
          <img src="/images/press/3/QfxTGrxg.png" alt="페이 인증 페이지" />
        </div>
        <div style={{ width: 10 + "px" }}></div>
        <div>
          <img src="/images/press/3/DavjRHcy.jpg" alt="포인트 취합 페이지" />
        </div>
        <div style={{ width: 10 + "px" }}></div>
        <div>
          <img src="/images/press/3/ywvNeczR.jpg" alt="포인트 취합 페이지 (2)" />
        </div>
      </div>
    </div>
    <div className="descImage">
      <div>
        <div>
          <img src="/images/press/3/diHHbqFV.jpg" alt="배송 페이지" />
        </div>
        <div style={{ width: 10 + "px" }}></div>
        <div>
          <img src="/images/press/3/nVwQhepd.jpg" alt="자동납부 신청 페이지" />
        </div>
        <div style={{ width: 10 + "px" }}></div>
        <div>
          <img src="/images/press/3/FtQCJreE.jpg" alt="페이머니 자동 결제 페이지" />
        </div>
        <div style={{ width: 10 + "px" }}></div>
        <div></div>
      </div>
    </div>
    <div className="desc bold">그림3 스마트폰샵 UI와 주요기능</div>
    <br />
    <br />
    <p>
      ㅣ 해당 샵에서는 삼성전자 갤럭시노트 20 / 20 Ultra 5G를 포함 LG전자의 벨벳, Q92, 애플 아이폰 등 다양한 스마트폰을 선택할 수
      있으며, 요금제는 기존 KT 요금제인 ‘슈퍼플랜 스페셜’, ‘슈퍼플랜 베이직’, ‘5G 슬림’ 등을 선택, 가입할 수 있다. 추후 KT의 일반
      요금제까지 확대될 제공할 예정이다.
      <br />
    </p>
    <br />
    <p>
      ㅣ 론칭 후 주문 고객에게는 선착순으로 카카오 서비스 이용권 혜택을 제공한다. 멜론 스트리밍 6개월 이용권을 선택하거나
      카카오페이지 캐시 + 카카오 T 할인쿠폰 구성 중 원하는 혜택을 선택할 수 있는 옵션으로 구성되었다. 멜론 이용권을 선택하면 해당
      서비스를 통해 원하는 음악을 무제한으로 6개월간 자유롭게 들을 수 있다. 카카오페이지와 카카오T 조합을 선택하면 제공되는 페이지
      캐시로 웹툰이나 영화, 방송 등 유로 콘텐츠를 즐길 수 있으며, 카카오T 쿠폰을 사용하여 저렴하게 택시 및 대리운전 서비스 이용이
      가능하다. 이용권 혜택 역시 카카오톡으로 발송될 예정이다.
      <br />
    </p>
    <br />
    <p>
      ㅣ 모든 개통 고객에는 웰컴 기프트 키트가 제공된다. 스마트폰과 함께 배송되는 웰컴 기프트 키트는 카카오프렌즈 라이언 무선충전
      거치대(또는 카카오 리틀프렌즈 리틀라이언 홀더스틱), 실리콘 케이스, 전면강화필름, 카메라보호 필름으로 구성되어 있다.
      <br />
    </p>
    <br />
    <div className="descImage">
      <div>
        <img src="/images/press/3/YgLtUcBu.jpg" alt="카카오 서비스 이용권" />
      </div>
      <div></div>
    </div>
    <br />
    <div className="desc bold">그림4 오픈이벤트 '카카오 서비스 이용권'</div>
    <br />
    <div className="descImage">
      <div>
        <img src="/images/press/3/urKYsQdd.jpg" alt="월컴 기프트 키트" />
      </div>
      <div></div>
    </div>
    <br />
    <div className="desc bold">그림5 오픈이벤트 '웰컴 기프트 키트</div>
    <br />
    <br />
    <p>
      ㅣ 이 외에도, 스테이지파이브의 ‘리틀프렌즈 안심알리미’ 서비스가 함께 무료 제공된다. 리틀프렌즈 안심알리미는 초등학생 부모를
      위한 자녀 안심 케어 서비스이다. 아이의 실시간 등하교 알림을 카카오톡으로 받아볼 수 있고, 함께 제공되는 모바일앱에서는 위치
      확인과 스마트폰 사용 관리 등의 기능을 이용할 수 있다.
      <br />
    </p>
    <br />
    <div className="descImage">
      <div>
        <img src="/images/press/3/TYVNJtdT.jpg" alt="리틀프렌즈 안심 알리미" />
      </div>
      <div></div>
    </div>
    <br />
    <div className="desc bold">그림6 리틀프렌즈 안심알리미 무료제공</div>
    <br />
    <p>
      ㅣ 상기 론칭 프로모션 내용은 카카오페이 내 ‘스마트폰샵’ 서비스탭에서도 확인 가능하다. 한편, 카카오페이∙스테이지파이브∙ KT는
      지난 6월 {"<"}비대면 이동통신 가입 서비스{">"} ICT 규제 샌드박스 허가를 받은 바 있다.
      <br />
    </p>
  </div>
)

const press3 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브 제휴협력실"
      writer="커뮤니케이션팀 pr@stagefive.com"
      date="2020년 9월 15일 즉시 배포 가능."
      pageInfo="(사진자료 메일 첨부함)"
      title="| 스테이지파이브, 카카오페이 내 '스마트폰샵' 오픈"
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press3
